/* a {
  color: #777;
  text-decoration: none;
  transition: 0.3s all ease;
}

a:hover {
  color: #000;
} */

h1,
h2,
h3,
h4,
h5,
h6,
.font-heading {
  font-family: "Poppins", sans-serif;
  color: #000;
}

.container {
  z-index: 2;
  position: relative;
}

.text-black {
  color: #000 !important;
}

.text-primary {
  color: #2d71a1 !important;
}

.border-top {
  border-top: 1px solid #f2f2f2 !important;
}

.border-bottom {
  border-bottom: 1px solid #f2f2f2 !important;
}

figure figcaption {
  margin-top: 0.5rem;
  font-style: italic;
  font-size: 0.8rem;
}

section {
  overflow: hidden;
}

.section {
  padding: 7rem 0;
}

.section-heading {
  font-size: 3rem;
  font-weight: 700;
  background: linear-gradient(-45deg, #3db3c5, #274685);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Default btn sre-tyling */
.btn {
  border: none;
  padding: 15px 30px !important;
}

.btn.btn-outline-white {
  border: 2px solid #fff;
  background: none;
  color: #fff;
}

.btn.btn-outline-white:hover {
  background: #fff;
  color: #2d71a1;
}

.btn.btn-primary {
  background: #2d71a1;
  background: linear-gradient(-45deg, #1391a5, #274685);
  color: #fff;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
}

/* Feature 1 */
.feature-1 .wrap-icon {
  margin: 0 auto;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 30px;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.feature-1 .wrap-icon.icon-1 {
  background: linear-gradient(-45deg, #3b87bd, #2d71a2);
}

.feature-1 .wrap-icon i {
  font-size: 40px;
  line-height: 0;
  color: #fff;
}

.feature-1 h3 {
  font-size: 20px;
}

.feature-1 p {
  color: #b1b1b1;
}

/* Step */
.step {
  box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 40px;
}

.step .number {
  background: linear-gradient(-45deg, #1391a5, #274685);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  letter-spacing: 0.2rem;
  margin-bottom: 10px;
  display: block;
}

.step p:last-child {
  margin-bottom: 0px;
}

/* Review */
.review h3 {
  font-size: 20px;
}

.review p {
  line-height: 1.8;
  font-style: italic;
  color: #333333;
}

.review .stars span {
  color: #FF8803;
}

.review .stars .muted {
  color: #ccc;
}

.review .review-user img {
  width: 70px;
  margin: 0 auto;
}


/* CTA Section */
.cta-section {
  background: linear-gradient(to right, rgb(39, 70, 133) 0%, rgb(61, 179, 197) 100%);
  color: #fff;
}

.cta-section h2 {
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .cta-section h2 {
    font-size: 2rem;
  }
}

.cta-section .btn {
  background: #000000;
  color: #fff;
}

.cta-section .btn i {
  margin-right: 5px;
  font-size: 24px;
  line-height: 0;
}


@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero-section {
  /* background: linear-gradient(to right, rgba(39, 70, 133, 0.8) 0%, rgba(61, 179, 197, 0.8) 100%), url("/img/hero-bg.jpg"); */
  background: linear-gradient(to right, rgba(39, 70, 133, 0.8) 0%, rgba(61, 179, 197, 0.8) 100%);
  /* background-image: url("./img/hero-bg.jpg"); */
  position: relative;
}

.hero-section .wave {
  width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  bottom: -150px;
}

@media screen and (max-width: 992px) {
  .hero-section .wave {
    bottom: -180px;
  }
}

.hero-section .wave svg {
  width: 100%;
}

.hero-section,
.hero-section>.container>.row {
  height: 100vh;
  min-height: 880px;
}

.hero-section.inner-page {
  height: 60vh;
  min-height: 0;
}

.hero-section.inner-page .hero-text {
  transform: translateY(-150px);
  margin-top: -120px;
}

@media screen and (max-width: 992px) {
  .hero-section.inner-page .hero-text {
    margin-top: -80px;
  }
}

.hero-section h1 {
  font-size: 3.5rem;
  color: #fff;
  font-weight: 700;
  margin-bottom: 30px;
}

@media screen and (max-width: 992px) {
  .hero-section h1 {
    font-size: 2.5rem;
    text-align: center;
    margin-top: 40px;
  }
}

@media screen and (max-width: 992px) {
  .hero-section .hero-text-image {
    margin-top: 4rem;
  }
}

.hero-section p {
  font-size: 18px;
  color: #fff;
}

.hero-section .iphone-wrap {
  position: relative;
}

@media screen and (max-width: 992px) {
  .hero-section .iphone-wrap {
    text-align: center;
  }
}

.hero-section .iphone-wrap .phone-2,
.hero-section .iphone-wrap .phone-1 {
  position: absolute;
  top: -50%;
  overflow: hidden;
  left: 0;
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.3);
  border-radius: 30px;
}

@media screen and (max-width: 992px) {

  .hero-section .iphone-wrap .phone-2,
  .hero-section .iphone-wrap .phone-1 {
    position: relative;
    top: 0;
    max-width: 100%;
  }
}

.hero-section .iphone-wrap .phone-2,
.hero-section .iphone-wrap .phone-1 {
  width: 250px;
}

@media screen and (max-width: 992px) {
  .hero-section .iphone-wrap .phone-1 {
    margin-left: -150px;
  }
}

.hero-section .iphone-wrap .phone-2 {
  margin-top: 50px;
  margin-left: 100px;
  width: 250px;
}

@media screen and (max-width: 992px) {
  .hero-section .iphone-wrap .phone-2 {
    width: 250px;
    position: absolute;
    margin-top: 0px;
    margin-left: 100px;
  }
}











/* NEWSLETTER */
main {
  background: #f9f4e8;
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
}
main section.section-1 {
  width: 80%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  row-gap: 50px;
}

main section.section-1 .section-1-main {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  column-gap: 100px;
}

main section.section-1 .section-1-main .section-1-content {
  width: 60%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

main section.section-1 .section-1-main .section-1-content .section-1-title {
  font-size: 40px;
  line-height: 42px;
}

main section.section-1 .section-1-main .section-1-content .section-1-desc, main section.section-1 .section-1-main .section-1-content .section-1-alt-txt {
  color: #83817e;
  line-height: 24px;
}
main section.section-1 .section-1-main .section-1-content .section-1-form {
  width: 100%;
  display: flex;
  column-gap: 12px;
  align-items: center;
}
main section.section-1 .section-1-main .section-1-content .section-1-form input {
  width: 230px;
  padding: 12px 15px;
  outline: none;
  border: 0px;
  font-size: 16px;
  color: #83817e;
  font-weight: 500;
  background: #fff;
}
main section.section-1 .section-1-main .section-1-content .section-1-form button {   
  color: #fff;   
  background: #4977ea;  
  outline: none;  
  border: 0px;   
  padding: 12px 15px;   
  font-size: 15px;    
  border-radius: 5px;    
  cursor: pointer;
}
main section.section-1 .section-1-main .section-1-content .section-1-alt-txt {
  display: flex;
  column-gap: 12px;
  align-items: center;
}
main section.section-1 .section-1-main .section-1-content .section-1-alt-txt img {
  display: block;
}


main section.section-1 .section-1-main .section-1-imgs {
  width: 50%;
  height: 600px;
  position: relative;
}
main section.section-1 .section-1-main .section-1-imgs .section-1-imgs-main {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
main section.section-1 .section-1-main .section-1-imgs .section-1-imgs-main img {
  width: 90%;
  display: block;
  position: absolute;
}
/* main section.section-1 .section-1-main .section-1-imgs .section-1-imgs-main img:nth-child(1) {
  transform: rotate(-45deg);
}
main section.section-1 .section-1-main .section-1-imgs .section-1-imgs-main img:nth-child(2) { 
  transform: rotate(-30deg);
}
main section.section-1 .section-1-main .section-1-imgs .section-1-imgs-main img:nth-child(3) {  
  transform: rotate(-15deg);
} */



main section.section-1 footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
main section.section-1 footer ul {
  display: flex;
  list-style-type: none;
  align-items: center;
  column-gap: 25px;
}
main section.section-1 footer ul li a {
  text-decoration: none;
  display: block;
  font-weight: 500;
  color: #83817e;
}
main section.section-1 footer .user-content {
  display: flex;
  align-items: center;
  column-gap: 12px;
}
main section.section-1 footer .user-content .user-img img {
  display: block;
  width: 45px;
}
main section.section-1 footer .user-content .user-details {
  display: flex;
  flex-direction: column;
}
main section.section-1 footer .user-content .user-details h5 {
  font-weight: 500;
  font-size: 14px;
}
main section.section-1 footer .user-content .user-details h4 {
  font-weight: bold;
  font-size: 16px;
}
main section.section-1 footer .user-content .user-details h4 a {
  text-decoration: none;    
  color: #000;
}





@media only screen and (max-width: 1200px) {
  main {
      height: auto;
  }
  main section.section-1 {
      width: 90%;
      height: auto;
      padding: 35px 0px;
      row-gap: 20px;
  }
  main section.section-1 .section-1-main {
      flex-direction: column-reverse;
  }
  main section.section-1 .section-1-main .section-1-content {
      width: 100%;
      row-gap: 15px;
  }
  main section.section-1 .section-1-main .section-1-imgs {
      width: 50%;
  }
  main section.section-1 footer {
      margin-top: 25px;
  }
  main section.section-1 .section-1-main .section-1-content .section-1-form input {
      width: 300px;
      padding: 15px 20px;
  }
  main section.section-1 .section-1-main .section-1-content .section-1-form button {
      padding: 15px 20px;
  }
}

@media only screen and (max-width: 900px) {
  main section.section-1 nav .nav-main .nav-mobile-menu {
      display: block;
  }
  main section.section-1 nav .nav-main ul {
      display: none;
  }
}

@media only screen and (max-width: 600px) {
  main section.section-1 .section-1-main .section-1-content {
      row-gap: 20px;
  }
  main section.section-1 .section-1-main .section-1-content .section-1-form {
      flex-direction: column;
      row-gap: 15px;
  }
  main section.section-1 .section-1-main .section-1-content .section-1-form input, main section.section-1 .section-1-main .section-1-content .section-1-form button {
      width: 100%;
  }
  main section.section-1 .section-1-main .section-1-imgs {
      width: 80%;
  }
  main section.section-1 footer {
      flex-direction: column;
      row-gap: 25px;
  }
}




/* Social Media Icons on Thank You Page */

ul {
  list-style: none;
}

ul li {
  margin:5px 20px;
  /* float:right; */
  display: inline-block;
}

.wp-icon {
width: 40px;
height: 40px;
border-radius: 50%;
text-align: center;
line-height:40px;
vertical-align: middle;
color: #fff;
margin-right: 4px;
}
